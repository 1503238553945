<template>
  <div class="tickets-bag p30 bsbb">
    <van-nav-bar
      title="券包"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    />
    <div class="top">
      <div class="nav">
        <van-tabs v-model:active="active" background="#f2f2f2" color="green">
          <van-tab title="推荐使用">
            <div class="list df fdc">
              <div class="item p20 bsbb df aic jcsb">
                <div class="df fdc aic" style="margin: 10px 0 0 10px">
                  <p class="fz32 fw7 mb10">生鲜天天神券</p>
                  <p class="fz18">
                    <span style="color: green">仅剩10:52:40 </span> 规则 >
                  </p>
                </div>
                <div class="price df fdc aic">
                  <div style="color: green">
                    <span class="fz16">¥</span>
                    <span class="fz40">7</span>
                  </div>
                  <p style="color: #999" class="fz20">优惠券</p>
                  <van-button type="success">去使用</van-button>
                </div>
                <div class="tag fz16">送到家</div>
              </div>
              <div class="item p20 bsbb df aic jcsb">
                <div class="df fdc aic" style="margin: 10px 0 0 10px">
                  <p class="fz32 fw7 mb10">媒体优惠神券</p>
                  <p class="fz18">
                    <span style="color: green">明日到期 </span> 规则 >
                  </p>
                </div>
                <div class="price df fdc aic">
                  <div style="color: green">
                    <span class="fz16">¥</span>
                    <span class="fz40">15</span>
                  </div>
                  <p style="color: #999" class="fz20">优惠券</p>
                  <van-button type="success">去使用</van-button>
                </div>
                <div class="tag fz16">媒体优惠</div>
              </div>
            </div>
          </van-tab>
          <van-tab title="最新获得">内容 2</van-tab>
          <van-tab title="即将到期">内容 3</van-tab>
          <van-tab title="大额优先">内容 4</van-tab>
        </van-tabs>
      </div>
      <div class="drop">
        <van-dropdown-menu>
          <!-- <van-dropdown-item v-model="value" :options="options" /> -->
          <van-dropdown-item title="筛选" ref="item">
            <van-cell center title="包邮">
              <template #right-icon>
                <van-switch
                  v-model="switch1"
                  size="24"
                  active-color="#ee0a24"
                />
              </template>
            </van-cell>
            <van-cell center title="团购">
              <template #right-icon>
                <van-switch
                  v-model="switch2"
                  size="24"
                  active-color="#ee0a24"
                />
              </template>
            </van-cell>
            <div style="padding: 5px 16px">
              <van-button type="danger" block round @click="onConfirm">
                确认
              </van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <van-icon name="arrow-down" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      item: null,
      switch1: false,
      switch2: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onConfirm() {
      this.item.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__content {
  height: 80px !important;
}
::v-deep .van-nav-bar__title {
  font-size: 30px !important;
  padding: 10px !important;
  font-weight: 700;
  font-family: "pingfang";
}
::v-deep .van-tabs__wrap {
  height: 50px;
  padding-bottom: 10px;
}
::v-deep .van-tab__text--ellipsis {
  overflow: visible;
  font-size: 28px;
}
::v-deep .van-ellipsis {
  overflow: visible;
  font-size: 28px;
}
::v-deep .van-dropdown-menu__bar {
  background: #f2f2f2;
  box-shadow: none;
}
::v-deep .van-tab--active {
  font-weight: 700;
}
.tickets-bag {
  background: #f2f2f2;
  .top {
    position: relative;
    .nav {
      width: 560px;
      .list {
        margin-top: 40px;
        .item {
          margin-bottom: 20px;
          position: relative;
          width: 690px;
          height: 158px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 1);
          .price {
            border-left: 1px dashed #999;
            padding-left: 25px;
            width: 150px;
            height: 158px;
            position: relative;
            > div {
              position: absolute;
              top: -20px;
              left: 85px;
            }
            p {
              margin-top: 65px;
              margin-bottom: 10px;
            }
            .van-button {
              width: 122px;
              height: 42px;
              font-size: 14px !important;
              border-radius: 20px;
              background: #55bb49;
            }
          }
          .tag {
            position: absolute;
            top: 0;
            left: 0;
            background: #88e277;
            padding: 8px;
            box-sizing: border-box;
            color: #fff;
            border-radius: 20px 0 20px 0;
          }
        }
      }
    }
    .drop {
      position: absolute;
      right: 15px;
      top: 0;
    }
    .van-icon {
      position: absolute;
      right: 0px;
      top: 15px;
      font-size: 16px;
    }
  }
}
</style>